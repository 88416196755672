import React, {useEffect, useState} from "react";
import { Input, Row, Button, Space, message, Radio, Typography } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

import { makeRequest } from "../../utils/Api";

const { Title, Paragraph, Text, Link } = Typography;

const ChangePasswordModal = (props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");

  // Password validation rules
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [isMinLength, setIsMinLength] = useState(false);

  const handleChangePassword = async () => {
    const jwt_local = localStorage.getItem("token");

    try {
      await makeRequest('post', jwt_local, "change_password", {
        new_password: password,
      });
  
      props.setIsChangePasswordModalOpen(false);
      message.success("Password changed successfully");
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const updatePassword = (newPassword) => {
    setPassword(newPassword);

    setHasUpperCase(/[A-Z]/.test(newPassword));
    setHasNumber(/\d/.test(newPassword));
    setHasSymbol(/[!@#$%^&*]/.test(newPassword));
    setIsMinLength(newPassword.length >= 8);
  };

  const isPasswordValid = hasUpperCase && hasNumber && hasSymbol && isMinLength;

  return (
    <>
      <Space
        direction="vertical"
        size="middle"
        style={{
          display: 'flex',
        }}
      >
        <Input.Password
          placeholder="Password"
          visibilityToggle={{
            visible: passwordVisible,
            onVisibleChange: setPasswordVisible,
          }}
          value={password}
          onChange={(event) => updatePassword(event.target.value)}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />

        <Paragraph>
          <Text>Password must contain:</Text>
          <ul>
            <li style={{ color: hasUpperCase ? 'green' : 'red' }}>At least one capital letter</li>
            <li style={{ color: hasNumber ? 'green' : 'red' }}>At least one number</li>
            <li style={{ color: hasSymbol ? 'green' : 'red' }}>At least one symbol</li>
            <li style={{ color: isMinLength ? 'green' : 'red' }}>Minimum 8 characters</li>
          </ul>
        </Paragraph>

        <Row>
          <Button type="primary" onClick={handleChangePassword} disabled={!isPasswordValid}>Submit</Button>
        </Row>
      </Space>
    </>
  );
};

export default ChangePasswordModal;