import React, { useState, useEffect } from "react";
import { Typography, Form, Input, InputNumber, Button, Row, Col, Divider, Space, Popover } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';

const { Title } = Typography;

const ProfessionalComponent = ({
  negotiatedPayerRatePc,
  setNegotiatedPayerRatePc,
  negotiatedPayerRatePc_B,
  setNegotiatedPayerRatePc_B,
  coPayPc,
  setCoPayPc,
  coPayPc_B,
  setCoPayPc_B,
  coInsurancePc,
  setCoInsurancePc,
  deductibleRemainingPc,
  setDeductibleRemainingPc,
  deductibleRemainingPc_B,
  setDeductibleRemainingPc_B,
  outOfPocketRemainingPc,
  setOutOfPocketRemainingPc,
  outOfPocketRemainingPc_B,
  setOutOfPocketRemainingPc_B,
  estimatedPatientResponsibilityPc,
  setEstimatedPatientResponsibilityPc,
  coInsurancePercentagePc,
  setCoInsurancePercentagePc,
}) => {
  const [formLayout, setFormLayout] = useState("horizontal");

  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };

  useEffect(() => {
        const calculatedPercentage = ((negotiatedPayerRatePc_B - deductibleRemainingPc_B - coPayPc_B) * coInsurancePercentagePc) / 100;
        setCoInsurancePc(parseFloat(calculatedPercentage.toFixed(2)));
      }, [coInsurancePercentagePc, negotiatedPayerRatePc_B, deductibleRemainingPc_B, coPayPc_B]);

  const calculateSum = () => {
    const sum =
      parseFloat(negotiatedPayerRatePc.toFixed(2)) +
      parseFloat(coPayPc.toFixed(2)) +
      parseFloat(coInsurancePc.toFixed(2)) +
      parseFloat(deductibleRemainingPc.toFixed(2)) +
      parseFloat(outOfPocketRemainingPc.toFixed(2));
    setEstimatedPatientResponsibilityPc(sum);
  };
  

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 12,
          },
          wrapperCol: {
            span: 24,
          },
        }
      : null;

  const buttonItemLayout =
    formLayout === "horizontal"
      ? {
          wrapperCol: {
            span: 14,
            offset: 4,
          },
        }
      : null;

  const popoverContent = (
    <div>
      <p>Please note that in order for items to be added to the total patient responsibility</p>
      <p>they also need to be entered in the second column of the input boxes.</p>
    </div>
  );
  return (
    <>
      {/* <Typography.Title level={5} align="center">Professional Component</Typography.Title> */}
      <Divider orientation="left">Professional Component</Divider>
      <Popover
        content={popoverContent} 
        title="Please Note"
      >
        <Button><InfoCircleOutlined /></Button>
      </Popover>
      <Form {...formItemLayout} layout={formLayout}>
        <Form.Item label="Negotiated payer rate">
          <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
          <InputNumber
            defaultValue={negotiatedPayerRatePc_B}
            value={negotiatedPayerRatePc_B}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setNegotiatedPayerRatePc_B(parseFloat(value))}
          />
          <InputNumber
            defaultValue={negotiatedPayerRatePc}
            value={negotiatedPayerRatePc}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setNegotiatedPayerRatePc(parseFloat(value))}
          />
          </Space>
        </Form.Item>

        <Form.Item label="Co-pay">
        <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
          <InputNumber
            defaultValue={coPayPc_B}
            value={coPayPc_B}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setCoPayPc_B(parseFloat(value))}
          />
          <InputNumber
            defaultValue={coPayPc}
            value={coPayPc}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setCoPayPc(parseFloat(value))}
          />
          </Space>
        </Form.Item>


        <Form.Item label="Deductible Remaining">
        <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
        <InputNumber
            defaultValue={deductibleRemainingPc_B}
            value={deductibleRemainingPc_B}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setDeductibleRemainingPc_B(parseFloat(value))}
          />
          <InputNumber
            defaultValue={deductibleRemainingPc}
            value={deductibleRemainingPc}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setDeductibleRemainingPc(parseFloat(value))}
          />
          </Space>
        </Form.Item>

        <Form.Item label="Out of Pocket Remaining">
        <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
          <InputNumber
            defaultValue={outOfPocketRemainingPc_B}
            value={outOfPocketRemainingPc_B}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setOutOfPocketRemainingPc_B(parseFloat(value))}
          />
          <InputNumber
            defaultValue={outOfPocketRemainingPc}
            value={outOfPocketRemainingPc}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setOutOfPocketRemainingPc(parseFloat(value))}
          />
          </Space>
        </Form.Item>
        <Form.Item label="Co-Insurance Percentage">
        <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
          <InputNumber
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
            value={coInsurancePercentagePc}
            onChange={(value) => setCoInsurancePercentagePc(value)}
          />
        {coInsurancePc}
        </Space>
        </Form.Item>
        <Form.Item></Form.Item>
        <Form.Item {...buttonItemLayout}>
          <Row>
            <Col>
              <Button type="primary" onClick={calculateSum}>Calculate Professional Component</Button>
              <Divider />
              {/* <Title level={5}>{`Total: ${estimatedPatientResponsibilityPc}`}</Title> */}
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label="Professional Estimate Total">
        <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
          <InputNumber
            defaultValue={estimatedPatientResponsibilityPc}
            value={estimatedPatientResponsibilityPc}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setEstimatedPatientResponsibilityPc(parseFloat(value))}
          />
        </Space>
        </Form.Item>
      </Form>

      
    </>
  );
};

export default ProfessionalComponent;
