import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, Space, Typography, message } from 'antd';
import { makeUnauthenticatedRequest } from '../utils/Api';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import ResetPassword from '../components/ResetPassword';

const { Title, Paragraph } = Typography;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [resetPassword, setResetPassword] = useState(false);

  const handleForgotPassword = async () => {
    try {
      const response = await makeUnauthenticatedRequest('post', 'loggedout/forgot_password', {
        email: email,
      });
        if (response.status === 200) {
          setResetPassword(true);
        } else {
          message.error(response.data.message)
          // message.error('Invalid email');
        }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await makeUnauthenticatedRequest('post', 'loggedout/reset_password', {
        email: email,
        verification_code: code,
        new_password: password,
      });
        if (response.status === 200) {
          message.success('Password reset successfully');
          navigate('/', { replace: true });
        } else {
          message.error(response.data.message)
        }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleBack = () => {
    navigate('/', { replace: true });
  };


  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
    <Col>
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: 'flex',
      }}
    >
      <Title align="center" level={1}>CNTi Patient Estimator</Title>
      {resetPassword ? 
          <ResetPassword  
            handleResetPassword={handleResetPassword} 
            handleBack={handleBack}
            setPassword={setPassword}
            password={password}
            passwordVisible={passwordVisible}
            setPasswordVisible={setPasswordVisible}
            setCode={setCode}
            code={code}
          />
        : <ForgotPasswordForm 
            handleForgotPassword={handleForgotPassword} 
            handleBack={handleBack} 
            email={email}
            setEmail={setEmail}
          />
      }

      </Space>
    </Col>
    </div>
  );
};

export default ForgotPassword;