import React, { useState } from "react";
import { Input, Row, Button, Space, Typography } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

const { Title, Paragraph, Text, Link } = Typography;

const ResetPassword = (props) => {
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [isMinLength, setIsMinLength] = useState(false);

  const updatePassword = (newPassword) => {
    props.setPassword(newPassword);

    setHasUpperCase(/[A-Z]/.test(newPassword));
    setHasNumber(/\d/.test(newPassword));
    setHasSymbol(/[!@#$%^&*]/.test(newPassword));
    setIsMinLength(newPassword.length >= 8);
  };

  const isPasswordValid = hasUpperCase && hasNumber && hasSymbol && isMinLength;


  return (
    <>
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: 'flex',
      }}
    >
      <Paragraph align="center">Please, copy the code sent to your email and create a new strong password</Paragraph>
      <Input
        placeholder="Code"
        onChange={(code) => props.setCode(code.target.value)}
        value={props.code}
        fullwidth="true"
      />
        <Input.Password
          placeholder="New Password"
          visibilityToggle={{
            visible: props.passwordVisible,
            onVisibleChange: props.setPasswordVisible,
          }}
          value={props.password}
          onChange={(event) => updatePassword(event.target.value)}
          iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
        <Paragraph>
          <Text>Password must contain:</Text>
          <ul>
            <li style={{ color: hasUpperCase ? 'green' : 'red' }}>At least one capital letter</li>
            <li style={{ color: hasNumber ? 'green' : 'red' }}>At least one number</li>
            <li style={{ color: hasSymbol ? 'green' : 'red' }}>At least one symbol</li>
            <li style={{ color: isMinLength ? 'green' : 'red' }}>Minimum 8 characters</li>
          </ul>
        </Paragraph>
      <Space direction="horizontally">
        <Button type="primary" onClick={() => props.handleResetPassword()} disabled={!isPasswordValid}>Submit</Button>

        <Button type="link" onClick={() => props.handleBack()}>Back to Login</Button>
      </Space>
      </Space>
    </>
  );
};

export default ResetPassword;