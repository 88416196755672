import React, { useState, useEffect } from "react";
import { Typography, Form, Input, Button, Divider, InputNumber, Row, Col, Space, Popover } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';


const TechnicalComponent = ({
  totalChargesTc,
  setTotalChargesTc,
  totalChargesTc_B,
  setTotalChargesTc_B,
  setNegotiatedPayerRateTc,
  setNegotiatedPayerRateTc_B,
  negotiatedPayerRateTc,
  negotiatedPayerRateTc_B,
  coPayTc_B,
  setCoPayTc_B,
  coPayTc,
  setCoPayTc,
  coInsuranceTc,
  setCoInsuranceTc,
  deductibleRemainingTc,
  setDeductibleRemainingTc,
  deductibleRemainingTc_B,
  setDeductibleRemainingTc_B,
  outOfPocketRemainingTc,
  setOutOfPocketRemainingTc,
  outOfPocketRemainingTc_B,
  setOutOfPocketRemainingTc_B,
  estimatedPatientResponsibilityTc,
  setEstimatedPatientResponsibilityTc,
  coInsurancePercentageTc,
  setCoInsurancePercentageTc,
}) => {
  const [formLayout, setFormLayout] = useState("horizontal");
  
  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };
  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 12,
          },
          wrapperCol: {
            span: 24,
          },
        }
      : null;
  const buttonItemLayout =
    formLayout === "horizontal"
      ? {
          wrapperCol: {
            span: 14,
            offset: 4,
          },
        }
      : null;

      
      const calculateSum = () => {
        const sum =
          parseFloat(negotiatedPayerRateTc.toFixed(2)) +
          parseFloat(totalChargesTc.toFixed(2)) +
          parseFloat(coPayTc.toFixed(2)) +
          parseFloat(coInsuranceTc.toFixed(2)) +
          parseFloat(deductibleRemainingTc.toFixed(2)) +
          parseFloat(outOfPocketRemainingTc.toFixed(2));
        setEstimatedPatientResponsibilityTc(sum);
      };

      useEffect(() => {
        const calculatedPercentage = ((negotiatedPayerRateTc_B - deductibleRemainingTc_B - coPayTc_B) * coInsurancePercentageTc) / 100;
        setCoInsuranceTc(parseFloat(calculatedPercentage.toFixed(2)));
      }, [coInsurancePercentageTc, negotiatedPayerRateTc_B, deductibleRemainingTc_B, coPayTc_B]);
  
  
  const popoverContent = (
    <div>
      <p>Please note that in order for items to be added to the total patient responsibility</p>
      <p>they also need to be entered in the second column of the input boxes.</p>
    </div>
  );
  return (
    <>
      {/* <Typography.Title level={5} align="left">Technical Component</Typography.Title> */}
      <Divider orientation="left">Technical Component</Divider>
      <Popover
        content={popoverContent} 
        title="Please Note"
      >
        <Button><InfoCircleOutlined /></Button>
      </Popover>
      <Form {...formItemLayout} layout={formLayout}>
        <Form.Item label="Total Charges">
        <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
          <InputNumber
            value={totalChargesTc_B}
            defaultValue={totalChargesTc_B}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setTotalChargesTc_B(parseFloat(value))}
          />
          <InputNumber
            value={totalChargesTc}
            defaultValue={totalChargesTc}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setTotalChargesTc(parseFloat(value))}
          />
        </Space>
        </Form.Item>

        <Form.Item label="Negotiated Payer Rate">
        <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
          <InputNumber
            defaultValue={negotiatedPayerRateTc_B}
            value={negotiatedPayerRateTc_B}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setNegotiatedPayerRateTc_B(parseFloat(value))}
          />
          <InputNumber
            defaultValue={negotiatedPayerRateTc}
            value={negotiatedPayerRateTc}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setNegotiatedPayerRateTc(parseFloat(value))}
          />
        </Space>
        </Form.Item>

        <Form.Item label="Co-pay">
        <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
          <InputNumber
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            value={coPayTc_B}
            defaultValue={coPayTc_B}
            onChange={(value) => setCoPayTc_B(parseFloat(value))}
          />
          <InputNumber
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            value={coPayTc}
            defaultValue={coPayTc}
            onChange={(value) => setCoPayTc(parseFloat(value))}
          />
        </Space>
        </Form.Item>


        <Form.Item label="Deductible Remaining">
        <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
          <InputNumber
            value={deductibleRemainingTc_B}
            defaultValue={deductibleRemainingTc_B}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setDeductibleRemainingTc_B(parseFloat(value))}
          />
          <InputNumber
            value={deductibleRemainingTc}
            defaultValue={deductibleRemainingTc}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setDeductibleRemainingTc(parseFloat(value))}
          />
          </Space>
        </Form.Item>

        <Form.Item label="Out of Pocket Remaining">
        <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
          <InputNumber
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            value={outOfPocketRemainingTc_B}
            defaultValue={outOfPocketRemainingTc_B}
            onChange={(value) => setOutOfPocketRemainingTc_B(parseFloat(value))}
          />
          <InputNumber
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            value={outOfPocketRemainingTc}
            defaultValue={outOfPocketRemainingTc}
            onChange={(value) => setOutOfPocketRemainingTc(parseFloat(value))}
          />
          </Space>
        </Form.Item>
        <Form.Item label="Co-Insurance Percentage">
        <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
          <InputNumber
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
            value={coInsurancePercentageTc}
            onChange={(value) => setCoInsurancePercentageTc(value)}
            />
          {coInsuranceTc}
        </Space>  
        </Form.Item>
        
        <Form.Item {...buttonItemLayout}>
          <Row>
            <Col >
              <Button type="primary" onClick={calculateSum}>Calculate Technical Component</Button>
              <Divider />
              {/* <Title level={5}>{`Total: ${estimatedPatientResponsibilityTc}`}</Title> */}
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label="Technical Estimate Total">
          <InputNumber
            defaultValue={estimatedPatientResponsibilityTc}
            value={estimatedPatientResponsibilityTc}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setEstimatedPatientResponsibilityTc(parseFloat(value))}
          />
        </Form.Item>
      </Form>
    </>
  );
};

export default TechnicalComponent;
