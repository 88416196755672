import React, { useState } from "react";

import {
  Button,
  Space,
  Descriptions,
} from "antd";

const PatientInformationTable = (props) => {
  const [showAllKeys, setShowAllKeys] = useState(false);

  const visibleKeys = [
    "location",
    "mrn",
    "acc",
    "pat_type",
    "last_name",
    "first_name",
    "dob",
    "sched_on",
    "exam",
    "exam_description",
    "modifier",
    "dos",
  ];

  const toggleShowAllKeys = () => {
    setShowAllKeys((prevShowAllKeys) => !prevShowAllKeys);
  };

  const renderedKeys = showAllKeys ? Object.keys(props.record) : visibleKeys;

  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Descriptions title="Patient Information" bordered>
          {renderedKeys.map((key) => (
            <Descriptions.Item key={key} label={<strong>{key}</strong>}>
              {props.record[key]}
            </Descriptions.Item>
          ))}
        </Descriptions>
        <Button onClick={toggleShowAllKeys} type="link">
          {showAllKeys ? "Hide details" : "Expand patient details"}
        </Button>
      </Space>
    </>
  );
};

export default PatientInformationTable;
