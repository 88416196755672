import React, { useState, useEffect } from "react";
import { DatePicker, Select, Button, Row, Table, Space, Input, Spin, message, Form, Divider } from "antd";
import { makeRequest } from "../utils/Api";
import { useNavigate } from "react-router-dom";
import CsvDownloadButton from 'react-json-to-csv'

const { RangePicker } = DatePicker;
const { Option } = Select;

const PatientTable = ({}) => {
  let navigate = useNavigate();
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [location, setLocation] = useState("");
  const [isGenerated, setIsGenerated] = useState(false);
  const [isToPrint, setIsToPrint] = useState(false);
  const [mrn, setMrn] = useState("");
  const [estimateID, setEstimateID] = useState("");
  const [filteredData, setFilteredData] = useState([]); 
  const [dosFilter, setDosFilter] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [csvDownloadButton, setCsvDownloadButton] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const columns = [
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          {record.estimate_generated ? (
            <Space size="middle">
              <Button type="primary" onClick={() => handleOpenInfo(record)}>
                Edit
              </Button>
              <Button type="primary" onClick={() => handleOpenPdf(record)}>
                View
              </Button>
            </Space>
          ) : (
            <Button type="primary" onClick={() => handleOpenInfo(record)}>
              Create
            </Button>
          )}
        </span>
      ),
      width: 200,
      fixed: 'left',
    },
    {
      title: 'Estimate ID',
      dataIndex: 'estimate_id',
      key: 'estimate_id',
      render: (text, record) => {
        if (record.estimate_generated) {
          return <span>{record.estimate_id}</span>;
        } else {
          return <span>Not Generated</span>;
        }
      },
      width: 150,
    },
    {
      title: 'MRN',
      dataIndex: 'mrn',
      key: 'mrn',
      width: 150,
      // fixed: 'left',
    },
    {
      title: 'Account',
      dataIndex: 'acc',
      key: 'acc',
      width: 150,      
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: 150,
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 150,
    },
    {
      title: 'DOS',
      dataIndex: 'dos',
      key: 'dos',
      sorter: (a, b) => new Date(a.dos) - new Date(b.dos),
      sortDirections: ['ascend', 'descend'],
      width: 150,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: 150,
    },
    {
      title: 'Estimate Generated?',
      dataIndex: 'estimate_generated',
      key: 'estimate_generated',
      render: (text, record) => (
        <span>{record.estimate_generated ? 'YES' : 'NO'}</span>
      ),
      width: 150,
      // fixed: 'left',
    },
    {
      title: 'To Print?',
      dataIndex: 'to_print',
      key: 'to_print',
      render: (text, record) => (
        <span>{record.to_print ? 'YES' : 'NO'}</span>
      ),
      width: 150,
    },
    {
      title: 'Patient Type',
      dataIndex: 'pat_type',
      key: 'pat_type',
      width: 150,
    },
    
    {
      title: 'Date of Birth',
      dataIndex: 'dob',
      key: 'dob',
      width: 150,
    },
    {
      title: 'Sex',
      dataIndex: 'sex',
      key: 'sex',
      width: 150,
    },
    {
      title: 'Scheduled On',
      dataIndex: 'sched_on',
      key: 'sched_on',
      width: 150,
    },
    {
      title: 'Exam',
      dataIndex: 'exam',
      key: 'exam',
      width: 150,
    },
    // {
    //   title: 'Exam Description',
    //   dataIndex: 'exam_description',
    //   key: 'exam_description',
    //   width: 150,
    // },
    {
      title: 'Modifier',
      dataIndex: 'modifier',
      key: 'modifier',
      width: 150,
    },
    
    // {
    //   title: 'Billing Comments',
    //   dataIndex: 'billing_comments',
    //   key: 'billing_comments',
    //   width: 150,
    // },
    // {
    //   title: 'Preauthorization',
    //   dataIndex: 'preauthorization',
    //   key: 'preauthorization',
    //   width: 150,
    // },
    {
      title: 'Insurance 1',
      dataIndex: 'insurance1',
      key: 'insurance1',
      width: 150,
    },
    {
      title: 'Policy 1',
      dataIndex: 'policy1',
      key: 'policy1',
      width: 150,
    },
    {
      title: 'Insurance 2',
      dataIndex: 'insurance2',
      key: 'insurance2',
      width: 150,
    },
    {
      title: 'Policy 2',
      dataIndex: 'policy2',
      key: 'policy2',
      width: 150,
    },
    {
      title: 'Requesting',
      dataIndex: 'requesting',
      key: 'requesting',
      width: 150,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 150,
    },
    {
      title: 'Ex ST',
      dataIndex: 'ex_st',
      key: 'ex_st',
      width: 150,
    },
    {
      title: 'Resource',
      dataIndex: 'rsrc',
      key: 'rsrc',
      width: 150,
    },
    {
      title: 'Patient Location',
      dataIndex: 'patient_location',
      key: 'patient_location',
      width: 150,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 150,
    },
    {
      title: 'Address Line 1',
      dataIndex: 'address_line_1',
      key: 'address_line_1',
      width: 150,
    },
    {
      title: 'Address Line 2',
      dataIndex: 'address_line_2',
      key: 'address_line_2',
      width: 150,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 150,
    },
    {
      title: 'State Code',
      dataIndex: 'state_code',
      key: 'sate_code',
      width: 150,
    },
    {
      title: 'Postal Code',
      dataIndex: 'postal_code',
      key: 'postal_code',
      width: 150,
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      width: 150,
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 150,
    },
    {
      title: 'Email Count',
      dataIndex: 'email_count',
      key: 'email_count',
      width: 150,
    },
    {
      title: 'Last Email Sent At',
      dataIndex: 'last_email_sent_at',
      key: 'last_email_sent_at',
      width: 150,
    },
    {
      title: 'Last Email Sent By',
      dataIndex: 'sent_by',
      key: 'sent_by',
      width: 150,
    },
    {
      title: 'Email Opened At',
      dataIndex: 'email_opened_at',
      key: 'email_opened_at',
      width: 150,
    },
    {
      title: 'Attachment Opened At',
      dataIndex: 'attachment_opened_at',
      key: 'attachment_opened_at',
      width: 150,
    }

  ];
  

  useEffect(() => {
    let filtered = patients;
    if (dosFilter) {
      filtered = filtered.sort((a, b) => {
        const dateA = new Date(a.dos);
        const dateB = new Date(b.dos);
        if (dosFilter === 'ascend') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
    }
    setFilteredData(filtered);
  }, [dosFilter, patients]);

  const handleOpenInfo = (record) => {
    navigate('/patient-responsibility', {state: { record }});
  };

  const handleOpenPdf = async (record) => {
    console.log(record);
    const jwt = localStorage.getItem("token");
    
    try {
      const response = await makeRequest('post', jwt, "get_pdf", {
        patient_id: record.id,
        estimate_id: record.estimate_id,
      }, 'blob');
  
      if (response && response.data) {
        const type = "application/pdf";
        const file = new Blob([response.data], { type: type });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        message.success("Successfully retreived reporr");
      } else {
        message.error("Failed to retreive report.");
      }
    } catch (error) {
      return message.error(error.message);
    }
  };
  
  const handleDateChange = (dates) => {
    setSelectedDateRange(dates?.map((date) => {
      const formattedDate = new Date(date).toLocaleDateString(undefined, {
        month: 'numeric',
        day: 'numeric',
        year: '2-digit'
      });
    
      const formattedTime = new Date(date).toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric'
      });
      return `${formattedDate}`;
    }));
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  };

  const handleLocationChange = (value) => {
    setLocation(value);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  };

  const handleIsGenerated = (value) => {
    setIsGenerated(value);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  };

  const handleIsToPrint = (value) => {
    setIsToPrint(value);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  };

  const handleMrnChange = (e) => {
    setMrn(e.target.value);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setDosFilter(null);
    const jwt_local = localStorage.getItem("token");
    
    try {
      const res = await makeRequest('post', jwt_local, "patients", {
        date_range: selectedDateRange,
        location: location,
        is_generated: Boolean(isGenerated),
        mrn: mrn,
        to_print: Boolean(isToPrint),
        estimate_id: estimateID,
        page: tableParams.pagination.current,
        page_size: tableParams.pagination.pageSize,
      });
      setPatients(res.data.data.data);
      setLoading(false);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.data.data.total_records
        },
      })
      setTotalRecords(res.data.data.total_records);
      message.success("Successfully retreived patients");
      // setShowTable(true);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        message.error(error.response.data.message)
      }
      else {
        message.error('Unable to load data, please log in')
      }
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    })
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setPatients([]);
    }
  }

  const handleExport = async () => {
    setCsvDownloadButton(false);
    setLoading(true);
    const jwt = localStorage.getItem("token");
  
    try {
      const response = await makeRequest('post', jwt, "export_patient_csv", {
        date_range: selectedDateRange,
        location: location,
        is_generated: Boolean(isGenerated),
        mrn: mrn,
        to_print: Boolean(isToPrint),
        estimate_id: estimateID,
      });
  
      if (response && response.data) {
        const jsonToExport = response.data.data;
        setCsvData(jsonToExport);
        setCsvDownloadButton(true);
        setLoading(false);
        message.success("CSV file generated and downloaded successfully");
      } else {
        message.error("Failed to generate CSV file.");
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
  

  return (
    <>
    <Form onFinish={handleSubmit}>
      <Row>
        <Form.Item 
          name="isGenerated" 
          rules={[{ required: true, message: 'Please select whether the report is generated or not.' }]} 
          style={{ marginRight: 10 }}
        >
          <Select
            onChange={handleIsGenerated}
            placeholder="Is Report Generated?"
            allowClear
          >
            <Option value={true}>YES</Option>
            <Option value={false}>NO</Option>
          </Select>
        </Form.Item>
        <Form.Item name="dateRange" style={{ marginRight: 10 }}>
          <RangePicker onChange={handleDateChange} />
        </Form.Item>
        <Form.Item name="location" style={{ marginRight: 10 }}>
          <Select allowClear onChange={handleLocationChange} placeholder="Location">
            <Option value="NM">NM</Option>
            <Option value="DU">DU</Option>
            <Option value="QN">QN</Option>
            <Option value="PH">PH</Option>
            <Option value="BC">BC</Option>
            <Option value="BI">BI</Option>
            <Option value="RH">RH</Option>
            <Option value="SL">SL</Option>
            <Option value="RM">RM</Option>
            <Option value="KH">KH</Option>
            <Option value="MV">MV</Option>
          </Select>
        </Form.Item>
        <Form.Item name="isToPrint" style={{ marginRight: 10 }}>
          <Select
            onChange={handleIsToPrint}
            placeholder="Is Report to Print?"
            allowClear
          >
            <Option value={true}>YES</Option>
            <Option value={false}>NO</Option>
          </Select>
        </Form.Item>
        <Form.Item name="mrn" style={{ marginRight: 10 }}>
          <Input
            // value={mrn}
            onChange={handleMrnChange}
            // width={"100%"}
            placeholder={"MRN"}
          />
        </Form.Item>
        <Form.Item name="estimateId" style={{ marginRight: 10 }}>
          <Input
            // value={mrn}
            onChange={(e) => setEstimateID(e.target.value)}
            // width={"100%"}
            placeholder={"Estimate ID"}
          />
        </Form.Item>
        <Form.Item>
          <Space direction="horizontal" size="small" style={{ display: "flex" }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button type="primary" onClick={handleExport}>
              Export to CSV
            </Button>
            {csvDownloadButton && 
                <CsvDownloadButton delimiter="," data={csvData} style={{
                  backgroundColor: '#1677ff',
                  border: '1px solid #1677ff',
                  color: 'white',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}/>
            }
            {loading && <Spin />}
          </Space>
        </Form.Item>
      </Row>
    </Form>
    <Divider />
    <Row>
      <h3>Total Records: {totalRecords}</h3>  
    </Row>
    <Table
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={patients}
      scroll={{
        x: 1500,
        y: 500,
      }}
      pagination={tableParams.pagination}
      loading={loading}
      onChange={handleTableChange}
    />
    
    </>
  );
};

export default PatientTable;
