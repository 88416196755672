import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";


import {
  Col,
  Space,
  Button,
  Typography,
  Row,
  Layout,
  message,
  Image,
  Modal,
  Divider,
} from "antd";
import {
  LogoutOutlined,
} from "@ant-design/icons";
import logo from "../assets/CNTIT_logo_color.png";
import PatientTable from "../components/PatientTable";
import RegisterUser from "../components/modals/RegisterUser";
import UploadCsvModal from "../components/modals/UploadCsvModal";
import ChangePassword from "../components/modals/ChangePassword";

const { Header, Footer, Content } = Layout;
const { Title } = Typography;

const Main = () => {
  let navigate = useNavigate();
  const { setToken } = useAuth();
  
  const [messageApi, contextHolder] = message.useMessage();
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCsvModalOpen, setIsCsvModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);


  const handleLogout = () => {
    setToken()
    navigate('/', { replace: true });
  };

  return (
    <Space
    direction="vertical"
    style={{
      width: '100%',
    }}
    size={[0, 48]}
  >
    <Layout>
    <Header
        style={{
          background: "white",
        }}
      >
        <Row justify="space-between">
          <Col >
            <Image
              width={175}
              src={logo}
              preview={false}
              style={{ marginTop: 8 }}
            />
          </Col>
          <Col >
            <Title level={3}>Patient Responsibility Estimator List</Title>
          </Col>
          <Col >
            <Space direction="horizontal" size="small" style={{ display: "flex-end" }}>
            <Button type="link" onClick={() => setChangePasswordModalOpen(true)}>
              Change Password
            </Button>
            <Button
              type="link"
              onClick={handleLogout}
            >
              Logout <LogoutOutlined />
            </Button>
            </Space>
          </Col>
        </Row>
        
      </Header>
    <Layout>
      {contextHolder}
      <Content
        style={{
          padding: "0 20px",
          background: "white",
          paddingTop: 60
        }}
      >
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: "flex",
          }}
        >
          <Space direction="horizontal" size="middle" style={{ display: "flex" }}>
            <Button type="link" onClick={() => setIsModalOpen(true)}>
              Register New User
            </Button>
            <Button type="link" onClick={() => setIsCsvModalOpen(true)}>
              Upload CSV
            </Button>  
          </Space>
          <PatientTable />
        </Space>
      </Content>
      <Footer
        style={{
          textAlign: "center",
          background: "white",
        }}
      >
        <Divider />
      </Footer>
      <Modal 
        title="Register User" 
        open={isModalOpen} 
        onCancel={() => setIsModalOpen(false)}
        footer={
          <Button key="back" onClick={() => setIsModalOpen(false)}>Cancel</Button>
        }
      >
        <RegisterUser setIsModalOpen={setIsModalOpen} />
      </Modal>
      <Modal 
        title="Upload CSV" 
        open={isCsvModalOpen} 
        onCancel={() => setIsCsvModalOpen(false)}
        footer={''}
      >
        <UploadCsvModal setIsCsvModalOpen={setIsCsvModalOpen} />
      </Modal>
      <Modal 
        title="Change Password" 
        open={isChangePasswordModalOpen} 
        onCancel={() => setChangePasswordModalOpen(false)}
        footer={
          <Button key="back" onClick={() => setChangePasswordModalOpen(false)}>Cancel</Button>
        }
      >
        <ChangePassword setIsChangePasswordModalOpen={setChangePasswordModalOpen} />
      </Modal>
    </Layout>
  </Layout>
  </Space>
  );
};

export default Main;
