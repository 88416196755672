import axios from 'axios';
import { API_URL } from './Constants' 
import { message } from 'antd';

export function makeRequest(
  method, 
  jwt, 
  endpoint, 
  data = {}, 
  responseType = 'json', 
  contentType = 'application/json'
) {
  const config = {
    headers: {
      'Authorization': jwt,
      'Content-Type': contentType
    },
    responseType: responseType,
    withCredentials: true
  };

  const requestURL = `${API_URL}/${endpoint}`;

  if (method === 'get') {
    config.params = data;
    return axios.get(requestURL, config);
  } else if (method === 'post') {
    return axios.post(requestURL, data, config);
  } else {
    throw new Error('Unsupported HTTP method');
  }
}

export function makeUnauthenticatedRequest(
  method, 
  endpoint, 
  data = {}, 
  responseType = 'json', 
  contentType = 'application/json'
) {
  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': contentType,
    },
    responseType: responseType,
    withCredentials: true
  };

  const requestURL = `${API_URL}/${endpoint}`;

  if (method === 'get') {
    config.params = data;
    return axios.get(requestURL, config);
  } else if (method === 'post') {
    return axios.post(requestURL, data, config);
  } else {
    throw new Error('Unsupported HTTP method');
  }
}