import React from "react";
import { Input, Row, Button, Space } from "antd";

const Verify = (props) => {
  return (
    <>
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: 'flex',
      }}
    >
      <Input
        placeholder="Code"
        onChange={(code) => props.setCode(code.target.value)}
        value={props.code}
        fullwidth="true"
      />
      <Space direction="horizontally">
        <Button type="primary" onClick={() => props.handleVerify()}>Verify</Button>

        <Button onClick={() => props.handleLogin()}>Resend Code</Button>

        <Button type="link" onClick={() => props.handleBack()}>Back to Login</Button>
      </Space>
      </Space>
    </>
  );
};

export default Verify;