import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, Space, Typography, message } from 'antd';
import { makeUnauthenticatedRequest } from '../utils/Api';
import Login from '../components/Login';
import Verify from '../components/Verify';
import { useAuth } from "../provider/authProvider";

const { Title } = Typography;
const Unauth = () => {
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [verify, setVerify] = useState(false);
  const [code, setCode] = useState('');


  const handleLogin = async () => {
    try {
      const response = await makeUnauthenticatedRequest('post', 'loggedout/user_auth', {
        username: username,
        password: password,
      });

      if (response.status === 200) {
        setVerify(true);
      } else {
        message.error('Invalid username or password');
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleVerify = async () => {
    try {
      const response = await makeUnauthenticatedRequest('post', 'loggedout/verify', {
        username: username,
        verification_code: code,
      });

      const res = response.data;
      setToken(res.jwt);
      navigate('/', { replace: true });
    } catch (error) {
      if (error.response.status === 401) {
        message.error("Invalid username or password");
        return;
      } else {
        message.error(error.response.data.message);
      }
    }
  };

  const handleBack = () => {
    setVerify(false);
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password', { replace: true });
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
    <Col>
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: 'flex',
      }}
    >
      <Title align="center" level={1}>CNTi Patient Estimator</Title>
      {verify 
        ? <Verify 
            handleVerify={handleVerify} 
            code={code} 
            setCode={setCode} 
            handleBack={handleBack} 
            handleLogin={handleLogin}
          />
        : <Login 
            username={username}
            password={password}
            passwordVisible={passwordVisible}
            setPasswordVisible={setPasswordVisible}
            setUsername={setUsername}
            setPassword={setPassword}
            handleLogin={handleLogin}
            handleForgotPassword={handleForgotPassword}
            />
      }
      </Space>
    </Col>
    </div>
  );
};
export default Unauth;