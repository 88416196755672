import React, { useEffect, useState } from "react";
import { message } from 'antd';
import { useLocation } from "react-router-dom";
import { makeRequest } from "../utils/Api";
import ProfessionalComponent from "../components/ProfessionalComponent";
import TechnicalComponent from "../components/TechnicalComponent";
import PatientInformationTable from "../components/PatientInformationTable";
import { useNavigate } from "react-router-dom";

import {
  Divider,
  Row,
  Col,
  Button,
  Input,
  Space,
  Typography,
  Layout,
  Descriptions,
  Checkbox,
  Select,
  InputNumber,
} from "antd";

import { ArrowLeftOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;

const PatientResponsibility = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const record = location.state.record;
  
  // Fee List
  const [feeList, setFeeList] = useState([{ 
    visitProcedureId: null,
    feeType: '', 
    inputCode: '', 
    quantity: 1, 
    technicalMaxAllowedAmount: 0,
    description: '',
    professionalFee: 0,
    technicalFee: 0,
  }]);
  

  // Technical Component
  const [totalChargesTc, setTotalChargesTc] = useState(0);
  const [totalChargesTc_B, setTotalChargesTc_B] = useState(0);
  const [negotiatedPayerRateTc, setNegotiatedPayerRateTc] = useState(0);
  const [negotiatedPayerRateTc_B, setNegotiatedPayerRateTc_B] = useState(0);
  const [coPayTc, setCoPayTc] = useState(0);
  const [coPayTc_B, setCoPayTc_B] = useState(0);
  const [coInsuranceTc, setCoInsuranceTc] = useState(0);
  const [coInsurancePercentageTc, setCoInsurancePercentageTc] = useState(0);
  const [deductibleRemainingTc, setDeductibleRemainingTc] = useState(0);
  const [deductibleRemainingTc_B, setDeductibleRemainingTc_B] = useState(0);
  const [outOfPocketRemainingTc, setOutOfPocketRemainingTc] = useState(0);
  const [outOfPocketRemainingTc_B, setOutOfPocketRemainingTc_B] = useState(0);
  const [
    estimatedPatientResponsibilityTc,
    setEstimatedPatientResponsibilityTc,
  ] = useState(0);

  // Professional Component
  const [negotiatedPayerRatePc, setNegotiatedPayerRatePc] = useState(0);
  const [negotiatedPayerRatePc_B, setNegotiatedPayerRatePc_B] = useState(0);
  const [coPayPc_B, setCoPayPc_B] = useState(0);
  const [coPayPc, setCopayPc] = useState(0);
  const [coInsurancePc, setCoInsurancePc] = useState(0);
  const [coInsurancePercentagePc, setCoInsurancePercentagePc] = useState(0);
  const [deductibleRemainingPc, setDeductibleRemainingPc] = useState(0);
  const [deductibleRemainingPc_B, setDeductibleRemainingPc_B] = useState(0);
  const [outOfPocketRemainingPc, setOutOfPocketRemainingPc] = useState(0);
  const [outOfPocketRemainingPc_B, setOutOfPocketRemainingPc_B] = useState(0);
  const [
    estimatedPatientResponsibilityPc,
    setEstimatedPatientResponsibilityPc,
  ] = useState(0);

  // Total
  const [estimatedResponsibilityTotal, setEstimatedResponsibilityTotal] =
    useState(0);
  const [estimateID, setEstimateID] = useState("");
  const [remarks, setRemarks] = useState("");
  const [toPrint, setToPrint] = useState(false);
  const [transactionID, setTransactionID] = useState('');
  const [payerName, setPayerName] = useState('');

  const [showEmail, setShowEmail] = useState(false);

  useEffect(() => {
    setEstimatedResponsibilityTotal(
      parseFloat(estimatedPatientResponsibilityTc.toFixed(2)) + parseFloat(estimatedPatientResponsibilityPc.toFixed(2))
    );
  }, [estimatedPatientResponsibilityTc, estimatedPatientResponsibilityPc]);

  const handleGenerateReport = async () => {
    const jwt_local = localStorage.getItem("token");
    const route = record.estimate_generated ? 'edit_transaction' : 'generate_transaction';
  
    try {
      const response = await makeRequest('post', jwt_local, route, {
        feeList: feeList,
        negotiatedPayerRateTc: negotiatedPayerRateTc,
        negotiatedPayerRateTc_B: negotiatedPayerRateTc_B,
        totalChargesTc: totalChargesTc,
        totalChargesTc_B: totalChargesTc_B,
        coPayTc: coPayTc,
        coPayTc_B: coPayTc_B,
        coInsuranceTc: coInsuranceTc,
        coInsurancePercentageTc: coInsurancePercentageTc,
        deductibleRemainingTc: deductibleRemainingTc,
        deductibleRemainingTc_B: deductibleRemainingTc_B,
        outOfPocketRemainingTc: outOfPocketRemainingTc,
        outOfPocketRemainingTc_B: outOfPocketRemainingTc_B,
        estimatedPatientResponsibilityTc: estimatedPatientResponsibilityTc,
        negotiatedPayerRatePc: negotiatedPayerRatePc,
        negotiatedPayerRatePc_B: negotiatedPayerRatePc_B,
        coPayPc: coPayPc,
        coPayPc_B: coPayPc_B,
        coInsurancePc: coInsurancePc,
        coInsurancePercentagePc: coInsurancePercentagePc,
        deductibleRemainingPc: deductibleRemainingPc,
        deductibleRemainingPc_B: deductibleRemainingPc_B,
        outOfPocketRemainingPc: outOfPocketRemainingPc,
        outOfPocketRemainingPc_B: outOfPocketRemainingPc_B,
        estimatedPatientResponsibilityPc: estimatedPatientResponsibilityPc,
        estimatedResponsibilityTotal: estimatedResponsibilityTotal,
        estimateID: estimateID,
        location: record.location,
        remarks: remarks,
        toPrint: toPrint,
        payerName: payerName,
        patient_id: record.id,
      }, 'blob');
  
      if (response && response.data) {
        const type = "application/pdf";
        const file = new Blob([response.data], { type: type });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        message.success("Report generated successfully");
        setShowEmail(true);
      } else {
        message.error("Failed to generate report.");
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const handleEmailReport = async () => {
    const jwt_local = localStorage.getItem("token");
  
    try {
      const response = await makeRequest('post', jwt_local, "send_email", {
        estimate_id: estimateID,
        patient_id: record.id,
        email: record.email,
      });
  
      if (response && response.data) {
        message.success("Email sent successfully");
      } else {
        message.error("Failed to send email.");
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const jwt_local = localStorage.getItem("token");
    const fetchData = async () => {
      if (record.estimate_generated) {
        try {
          const response = await makeRequest(
            "post",
            jwt_local,
            "get_transaction",
            {patient_id: record.id}
          );
          if (response && response.data && response.data.data) {
            setTransactionID(response.data.data.id);
            setFeeList(response.data.data.feeList);
            // Technical Component
            setTotalChargesTc(response.data.data.totalChargesTc);
            setTotalChargesTc_B(response.data.data.totalChargesTc_B);
            setNegotiatedPayerRateTc(response.data.data.negotiatedPayerRateTc);
            setNegotiatedPayerRateTc_B(response.data.data.negotiatedPayerRateTc_B);
            setCoPayTc(response.data.data.coPayTc);
            setCoPayTc_B(response.data.data.coPayTc_B);
            setCoInsuranceTc(response.data.data.coInsuranceTc);
            setCoInsurancePercentageTc(response.data.data.coInsurancePercentageTc);
            setDeductibleRemainingTc(response.data.data.deductibleRemainingTc);
            setDeductibleRemainingTc_B(response.data.data.deductibleRemainingTc_B);
            setOutOfPocketRemainingTc(response.data.data.outOfPocketRemainingTc);
            setOutOfPocketRemainingTc_B(response.data.data.outOfPocketRemainingTc_B);
            setEstimatedPatientResponsibilityTc(response.data.data.estimatedPatientResponsibilityTc);
            // Professional Component
            setNegotiatedPayerRatePc(response.data.data.negotiatedPayerRatePc);
            setNegotiatedPayerRatePc_B(response.data.data.negotiatedPayerRatePc_B);
            setCopayPc(response.data.data.coPayPc);
            setCoPayPc_B(response.data.data.coPayPc_B);
            setCoInsurancePc(response.data.data.coInsurancePc);
            setCoInsurancePercentagePc(response.data.data.coInsurancePercentagePc);
            setDeductibleRemainingPc(response.data.data.deductibleRemainingPc);
            setDeductibleRemainingPc_B(response.data.data.deductibleRemainingPc_B);
            setOutOfPocketRemainingPc(response.data.data.outOfPocketRemainingPc);
            setOutOfPocketRemainingPc_B(response.data.data.outOfPocketRemainingPc_B);
            setEstimatedPatientResponsibilityPc(response.data.data.estimatedPatientResponsibilityPc);
            // Total
            setRemarks(response.data.data.remarks);
            setToPrint(response.data.data.toPrint);
            setEstimatedResponsibilityTotal(response.data.data.estimatedResponsibilityTotal);
            setEstimateID(response.data.data.estimateID);
            // setPayerName(response.data.data.payerName);
          } else {
            message.error("Failed to fetch transaction data.");
          }
        } catch (error) {
          message.error(error.response.data.message);
        }
      }
    };
    fetchData();
  }, []);

  

  // feeList handles
  const handleFeeTypeChange = (value, index) => {
    const newList = [...feeList];
    newList[index].feeType = value;
    setFeeList(newList);
  };

  const handleInputChange = (value, index, fieldName) => {
    const newList = [...feeList];
    newList[index][fieldName] = value;
    setFeeList(newList);
  };

  const handleMaxAllowedInputChange = (value, index, fieldName) => {
    const newList = [...feeList];
    newList[index][fieldName] = value;
    setFeeList(newList);
  };

  const handleTechnicalFeeManuallyEntered = (value, index, fieldName) => {
    const newList = [...feeList];
    newList[index][fieldName] = value;
    setFeeList(newList);
  };

  const handleAddFee = () => {
    setFeeList([...feeList, { 
      visitProcedureId: null,
      feeType: '', 
      inputCode: '', 
      quantity: 1, 
      technicalMaxAllowedAmount: 0,
      professionalFee: 0,
      technicalFee: 0,
      description: '',
      // technicalFeeManuallyEntered: 0,
    }]);
  };

  const handleRemoveFee = (index) => {
    const newList = [...feeList];
    newList.splice(index, 1);
    setFeeList(newList);
  };

  const handleGetFee = async () => {
    const jwt_local = localStorage.getItem("token");
  
    try {
      const response = await makeRequest('post', jwt_local, "get_fee", {
        fee_list: feeList,
      });
  
      if (response && response.data && response.data.data) {
        const responseData = response.data.data;
        console.log(responseData);
  
        setNegotiatedPayerRatePc_B(responseData.totalProfessionalFee);
        setNegotiatedPayerRateTc_B(responseData.totalTechnicalFee);
        setTotalChargesTc_B(responseData.totalTechnicalMaxAllowedAmount);
        setFeeList(responseData.feeList);
        
        message.success("Fee retrieved successfully");
      } else {
        message.error("Failed to retrieve fee.");
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  return (
    <Layout style={{ margin: "16px", backgroundColor: "white" }}>
      <Row justify="start" style={{ marginBottom: "16px" }}>
        <Col>
          <Button onClick={() => navigate(-1)}>
            <ArrowLeftOutlined />
          </Button>
        </Col>
      </Row>
      <Divider orientation="left">Visit information</Divider>
        <PatientInformationTable record={record} />
      <>
      <Divider orientation="left">Anticipated Services</Divider>
      {feeList.map((fee, index) => (
        <Space
          key={index}
          direction="horizontal"
          size="middle"
          style={{ display: 'flex', marginBottom: '8px' }}
        >
          <Input
            placeholder="Input code"
            value={fee.inputCode}
            onChange={(e) => handleInputChange(e.target.value, index, 'inputCode')}
          />

          <Select
            placeholder="Select fee type"
            value={feeList[index].feeType !== null ? feeList[index].feeType : undefined}
            onChange={(value) => handleFeeTypeChange(value, index)}
            style={{ width: '100%' }}
          >
            <Option value="" disabled>Select a fee type</Option>
            <Option value="fee">Insured</Option>
            <Option value="level_1">Charity Level 1</Option>
            <Option value="level_2">Charity Level 2</Option>
            <Option value="level_3">Charity Level 3</Option>
            <Option value="level_4">Charity Level 4</Option>
            <Option value="level_5">Charity Level 5</Option>
            <Option value="level_6">Charity Level 6</Option>
            <Option value="self_pay">Self Pay</Option>
            <Option value="international_payment_discount">
              International Payment Discount
            </Option>
          </Select>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Quantity:</span>
            <InputNumber
              min={1}
              max={10}
              value={fee.quantity}
              onChange={(value) => handleInputChange(value, index, 'quantity')}
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Technical Charge:</span>
            <InputNumber
              placeholder="Technical Charge"
              value={fee.technicalFee}
              defaultValue={fee.technicalFee}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={(value) =>
                handleTechnicalFeeManuallyEntered(value, index, 'technicalFee')
              }
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>Technical Max Allowed Amount:</span>
              <InputNumber
                placeholder="Technical Max Allowed Amount"
                value={fee.technicalMaxAllowedAmount}
                defaultValue={fee.technicalMaxAllowedAmount}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                onChange={(value) =>
                  handleMaxAllowedInputChange(value, index, 'technicalMaxAllowedAmount')
                }
              />
            </div>

          {index !== feeList.length - 1 ? (
            <Button type="primary" onClick={() => handleRemoveFee(index)} disabled={feeList.length <= 1}>
              Remove
            </Button>
          ) : (
            <>
              <Button type="primary" onClick={handleAddFee}>
                Add
              </Button>
              <Button type="primary" onClick={() => handleRemoveFee(index)} disabled={feeList.length <= 1}>
                Remove
              </Button>
              <Button type="primary" onClick={handleGetFee}>
                Get Fees
              </Button>
            </>
          )}
        </Space>
      ))}
    </>
        <>
          <Divider orientation="left">Patient Responsibility</Divider>
            {feeList.map((item, index) => (
              
              <Descriptions title={`Code: ${item.inputCode}`}>  
                <Descriptions.Item label="Fee Description">
                  {item.description}
                </Descriptions.Item>
                <Descriptions.Item label="Technical Negotiated rate">
                  {`${item.technicalFee} x ${item.quantity}`} {/* Assuming you have a 'quantity' variable */}
                </Descriptions.Item>
                <Descriptions.Item label="Professional Negotiated rate">
                  {`${item.professionalFee} x ${item.quantity}`} {/* Assuming you have a 'quantity' variable */}
                </Descriptions.Item>
              </Descriptions>
            ))}
        </>
          <Row gutter={16} justify="left">
            <Col span={12} align="left">
              <TechnicalComponent
                totalChargesTc={totalChargesTc}
                totalChargesTc_B={totalChargesTc_B}
                setTotalChargesTc_B={setTotalChargesTc_B}
                setTotalChargesTc={setTotalChargesTc}
                negotiatedPayerRateTc={negotiatedPayerRateTc}
                negotiatedPayerRateTc_B={negotiatedPayerRateTc_B}
                setNegotiatedPayerRateTc={setNegotiatedPayerRateTc}
                setNegotiatedPayerRateTc_B={setNegotiatedPayerRateTc_B}
                coPayTc={coPayTc}
                setCoPayTc={setCoPayTc}
                coPayTc_B={coPayTc_B}
                setCoPayTc_B={setCoPayTc_B}
                coInsuranceTc={coInsuranceTc}
                setCoInsuranceTc={setCoInsuranceTc}
                deductibleRemainingTc={deductibleRemainingTc}
                setDeductibleRemainingTc={setDeductibleRemainingTc}
                deductibleRemainingTc_B={deductibleRemainingTc_B}
                setDeductibleRemainingTc_B={setDeductibleRemainingTc_B}
                outOfPocketRemainingTc={outOfPocketRemainingTc}
                setOutOfPocketRemainingTc={setOutOfPocketRemainingTc}
                outOfPocketRemainingTc_B={outOfPocketRemainingTc_B}
                setOutOfPocketRemainingTc_B={setOutOfPocketRemainingTc_B}
                estimatedPatientResponsibilityTc={
                  estimatedPatientResponsibilityTc
                }
                setEstimatedPatientResponsibilityTc={
                  setEstimatedPatientResponsibilityTc
                }
                coInsurancePercentageTc={coInsurancePercentageTc}
                setCoInsurancePercentageTc={setCoInsurancePercentageTc}
              />
            </Col>
            <Col span={12} align="left">
              <ProfessionalComponent
                negotiatedPayerRatePc={negotiatedPayerRatePc}
                setNegotiatedPayerRatePc={setNegotiatedPayerRatePc}
                negotiatedPayerRatePc_B={negotiatedPayerRatePc_B}
                setNegotiatedPayerRatePc_B={setNegotiatedPayerRatePc_B}
                coPayPc={coPayPc}
                setCoPayPc={setCopayPc}
                coPayPc_B={coPayPc_B}
                setCoPayPc_B={setCoPayPc_B}
                coInsurancePc={coInsurancePc}
                setCoInsurancePc={setCoInsurancePc}
                deductibleRemainingPc={deductibleRemainingPc}
                setDeductibleRemainingPc={setDeductibleRemainingPc}
                deductibleRemainingPc_B={deductibleRemainingPc_B}
                setDeductibleRemainingPc_B={setDeductibleRemainingPc_B}
                outOfPocketRemainingPc={outOfPocketRemainingPc}
                setOutOfPocketRemainingPc={setOutOfPocketRemainingPc}
                outOfPocketRemainingPc_B={outOfPocketRemainingPc_B}
                setOutOfPocketRemainingPc_B={setOutOfPocketRemainingPc_B}
                estimatedPatientResponsibilityPc={
                  estimatedPatientResponsibilityPc
                }
                setEstimatedPatientResponsibilityPc={
                  setEstimatedPatientResponsibilityPc
                }
                coInsurancePercentagePc={coInsurancePercentagePc}
                setCoInsurancePercentagePc={setCoInsurancePercentagePc}
              />
            </Col>
          </Row>
          <Divider orientation="left">Generate Report</Divider>
          <Row gutter={16}>
            <Space direction="vertical">
              <Typography.Title level={4}>
                Estimate Patient Responsibility Total: ${estimatedResponsibilityTotal}
              </Typography.Title>
              <Input
                placeholder="Estimate ID"
                value={estimateID}
                onChange={(e) => setEstimateID(e.target.value)}
              ></Input>
              <Input
                placeholder="Override payer name"
                value={payerName}
                onChange={(e) => setPayerName(e.target.value)}
              ></Input>
              <Checkbox checked={toPrint} onChange={(e) => setToPrint(e.target.checked)}>Report to print?</Checkbox>
              <TextArea
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                placeholder="Remarks..."
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
              />
              <Space direction="horizontal">
                <Button type="primary" onClick={handleGenerateReport}>
                  {record.estimate_generated ? 'Edit Report' : 'Generate Report'}
                </Button>
                {(showEmail || record.estimate_generated) && (record.email !== '' && record.email !== null) && (
                    <Button type="primary" onClick={handleEmailReport}>
                      Send Secure Email
                    </Button>
                )}
              </Space>
            </Space>
          </Row>
    </Layout>
  );
};

export default PatientResponsibility;
