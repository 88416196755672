import React from "react";
import { Input, Row, Button, Space, Typography } from "antd";

const { Title, Paragraph, Text, Link } = Typography;

const ForgotPasswordForm = (props) => {
  return (
    <>
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: 'flex',
      }}
    >
      <Paragraph align="center">Provide the email used for your account. You will get a code to change your password.</Paragraph>
      <Input
        placeholder="Email"
        onChange={(email) => props.setEmail(email.target.value)}
        value={props.email}
        fullwidth="true"
      />
      <Space direction="horizontally">
        <Button type="primary" onClick={() => props.handleForgotPassword()}>Submit</Button>

        <Button type="link" onClick={() => props.handleBack()}>Back to Login</Button>
      </Space>
      </Space>
    </>
  );
};

export default ForgotPasswordForm;