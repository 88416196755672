import React from "react";
import { Input, Row, Button, Space } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

const Login = (props) => {
  return (
    <>
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: 'flex',
      }}
    >
      <Input
        placeholder="Username"
        onChange={(username) => props.setUsername(username.target.value)}
        value={props.username}
        fullwidth="true"
      />
      <Input.Password
        placeholder="Password"
        visibilityToggle={{
          visible: props.passwordVisible,
          onVisibleChange: props.setPasswordVisible,
        }}
        value={props.password}
        onChange={(password) => props.setPassword(password.target.value)}
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />
      <Row>
        <Button type="primary" onClick={() => props.handleLogin()}>Sign In</Button>
        <Button type="link" onClick={() => props.handleForgotPassword()}>Forgot Password?</Button>
      </Row>
      </Space>
    </>
  );
};

export default Login;
