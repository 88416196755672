import React, {useState} from "react";
import { Input, Row, Button, Space, message, Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons';

import { makeRequest } from "../../utils/Api";

const UploadCsvModal = (props) => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleCancel = () => {
    setFileList([]);
    setUploading(false); 
    props.setIsCsvModalOpen(false);
  };

  const file_props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };
  

  const handleUploadCsv = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file);
    });
  
    setUploading(true);
    const jwt_local = localStorage.getItem("token");
  
    try {
      await makeRequest('post', jwt_local, "upload_csv", formData, 'json', 'multipart/form-data');
  
      setFileList([]);
      message.success("File uploaded successfully");
      props.setIsCsvModalOpen(false);
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: 'flex',
      }}
    >
      <Upload {...file_props}>
        <Button icon={<UploadOutlined />}>Select File</Button>
      </Upload>
      <Space
        size={10}
        direction="horizontal"
      >
      <Button
        type="primary"
        onClick={handleUploadCsv}
        disabled={fileList.length === 0}
        loading={uploading}
      >
        {uploading ? 'Uploading' : 'Start Upload'}
      </Button>
      <Button
        key="back"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      </Space>
      </Space>
    </>
  );
};

export default UploadCsvModal;
