import React, {useState} from "react";
import { Input, Row, Button, Space, message, Radio, Typography } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

import { makeRequest } from "../../utils/Api";

const { Title, Paragraph, Text, Link } = Typography;

const RegisterUser = (props) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [isSuperuser, setIsSuperuser] = useState(false);

  // Password validation rules
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [isMinLength, setIsMinLength] = useState(false);

  const handleRegisterUser = async () => {
    const jwt_local = localStorage.getItem("token");

    try {
      await makeRequest('post', jwt_local, "new_user", {
        username: username,
        email: email,
        password: password,
        isSuperuser: isSuperuser,
      });
  
      props.setIsModalOpen(false);
      message.success("User created successfully");
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const updatePassword = (newPassword) => {
    setPassword(newPassword);

    setHasUpperCase(/[A-Z]/.test(newPassword));
    setHasNumber(/\d/.test(newPassword));
    setHasSymbol(/[!@#$%^&*]/.test(newPassword));
    setIsMinLength(newPassword.length >= 8);
  };

  const isPasswordValid = hasUpperCase && hasNumber && hasSymbol && isMinLength;

  return (
    <>
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: 'flex',
      }}
    >
      <Input
        placeholder="Username"
        onChange={(username) => setUsername(username.target.value)}
        value={username}
        fullwidth="true"
      />
      <Input
        placeholder="Email"
        onChange={(email) => setEmail(email.target.value)}
        value={email}
        fullwidth="true"
      />
      <Input.Password
        placeholder="Password"
        visibilityToggle={{
          visible: passwordVisible,
          onVisibleChange: setPasswordVisible,
        }}
        value={password}
        // onChange={(password) => setPassword(password.target.value)}
        onChange={(event) => updatePassword(event.target.value)}
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />
      <Paragraph>
          <Text>Password must contain:</Text>
          <ul>
            <li style={{ color: hasUpperCase ? 'green' : 'red' }}>At least one capital letter</li>
            <li style={{ color: hasNumber ? 'green' : 'red' }}>At least one number</li>
            <li style={{ color: hasSymbol ? 'green' : 'red' }}>At least one symbol</li>
            <li style={{ color: isMinLength ? 'green' : 'red' }}>Minimum 8 characters</li>
          </ul>
        </Paragraph>
      <Radio.Group onChange={(e) => setIsSuperuser(e.target.value)} value={isSuperuser}>
        <Radio value={true}>Is Superuser</Radio>
        <Radio value={false}>Not Superuser</Radio>
      </Radio.Group>
      <Row>
        <Button type="primary" onClick={() => handleRegisterUser()} disabled={!isPasswordValid}>Register</Button>
      </Row>
      </Space>
    </>
  );
};

export default RegisterUser;